@import url("//hello.myfonts.net/count/3cbd4e");

@font-face {
  font-family: 'TeeFranklin';
  font-style: medium;
  font-weight: 500;
  font-display: swap;
  src: url('./fonts/TeeFranklin-Medium/font.woff2') format('woff2');
}

body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}